.section-list {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
}
.section-list__inner {
	padding: 104px 16px 32px;
}

.section-list__heading {
	margin-bottom: 40px;
}

.section-list__list {
	margin: -32px 0 0;
	padding: 0;
	list-style: none;
}
.section-list__list li {
	position: relative;
}
.section-list__entry {
	position: relative;
	z-index: 999;
	display: block;
	padding: 32px 0;
	border-bottom: 1px solid var(--color-gray);
	color: #000000;
	cursor: pointer;
	text-decoration: none;
}
.section-list__entry:hover {
	color: var(--color-red);
}

.section-list__entry > * {
	pointer-events: none;
}
.section-list__entry-label {
	margin-bottom: 8px;
}
.section-list_entry-image {
	position: relative;
	margin-bottom: 16px;
	width: 100%;
	height: 400px;
	border-radius: 16px;
	overflow: hidden;
}
.section-list_entry-image img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 16px;
	overflow: hidden;
	object-fit: cover;
}
.section-list_entry-image.for-desktop {
	display: none;
}

.type-social .section-list_entry-image {
	display: none;
}
.type-download .section-list_entry-image img{
	object-fit: contain;
}
.section_list__entry-last-col {
	text-transform: uppercase;
}
.section_list__entry-last-col.ttw-button {
	margin: 0;
	width: 100%;
}

.type-social .section-list__inner {
	padding-bottom: 76px;
}
.type-social .section-list__entry {
	padding: 24px 0;
	border-bottom: 1px solid #000000;
}
.type-social li:first-child .section-list__entry {
	border-top: 1px solid #000000;
}
.type-social .section-list__entry-label {
	display: inline-block;
	margin: 0;
}
.type-social .section_list__entry-last-col {
	float: right;
	margin-top: 3px;
	margin-right: 26px;
	width: 21px;
	height: 17px;
}

.type-download .section-list__entry-label {
	margin-bottom: 32px;
}
.type-download .section-list_entry-image {
	margin: 0 auto 32px;
	width: 262px;
	height: auto;
}
.type-download .section-list_entry-image img {
	position: relative;
}

@media (min-width: 768px) {
	.section-list__inner {
		padding: 112px 10vw 32px;
	}

	.section-list__list {
		margin: 0;
	}

	.section-list__entry,
	.type-social .section-list__entry {
		padding: 40px 0;
		border-bottom: 1px solid #000000;
	}
	li:first-child .section-list__entry {
		padding: 40px 0;
		border-top: 1px solid #000000;
	}

	.section-list__entry p,
	.type-download .section-list__entry-label {
		display: inline-block;
		margin-bottom: 0;
	}
	.section-list__entry .w-15 {
		padding-right: 16px;
		min-width: 15vw;
    vertical-align: top;
	}
	.section-list__entry .label-2 {
		width: 30vw;
	}

	p.section_list__entry-last-col {
		position: absolute;
    right: 24px;
    top: 50%;
    margin-top: -13px;
	}

	.section-list_entry-image.for-mobile {
		display: none;
	}
	.section-list_entry-image.for-desktop,
	.type-social .section-list_entry-image.for-desktop {
		position: absolute;
		display: block;
    top: 50%;
    left: 70%;
		margin: -191px 0 0 -143px;
    width: 287px;
    height: 383px;
    opacity: 0;
	}
	
	.type-social .section_list__entry-last-col {
		margin-top: 2px;
		width: 27px;
		height: 22px;
	}
	
	.type-download .section-list_entry-image {
		margin: -131px 0 0 -131px;
		width: 262px;
		height: 262px;	
	}
}