.section-lottery {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
}
.section-lottery__inner {
	padding: 48px 16px 32px;
}
.lottery-1 .section-lottery__inner,
.lottery-2 .section-lottery__inner {
	padding: 104px 16px 32px;
}

.section-lottery__before_heading {
	margin-bottom: 16px;
}
.section-lottery__heading {
	margin-bottom: 24px;
}
.section-lottery__description {
	margin-bottom: 0;
}
.section-lottery__marking {
	display: block;
}
.section-lottery__marked {
	background-color: var(--color-red);
	color: #ffffff;
}

.section-lottery__subheading {
	margin-bottom: 32px;
}
.lottery-3 .section-lottery__subheading,
.lottery-4 .section-lottery__subheading {
	display: none;
}

.section-lottery__order {
	margin-bottom: 16px;
}

.section-lottery__text-1 {
	margin-bottom: 16px;
}
.section-lottery__text-2 {
	margin-bottom: 24px;
}
.section-lottery__text-3 {
	margin-bottom: 40px;
}
.section-lottery__balls {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: -4px;
}
.ball {
	position: relative;
	display: inline-block;
	margin: 4px;
	width: 100px;
	height: 100px;
}
.ball img {
	position: absolute;
	top: -8%;
	left: -8%;
	width: 116%;
	height: 116%;
}

.section-lottery__numbers {
	display: flex;
	align-items: center;
	padding: 16px;
	border: 1px solid var(--color-red);
	border-radius: 200px;
}
.section-lottery__numbers p {
	margin: 0;
	color: var(--color-red);
	font-size: 24px;
	line-height: 24px;
}
.section-lottery__search-icon {
	margin-right: 8px;
	width: 32px;
	height: 32px;
	object-fit: contain;
}
.section-lottery__animated-lottery {
	width: 75%;
	max-width: 670px;
}
.section-lottery__twitter-outline {
	margin-left: auto;
	width: 32px;
	height: 32px;
	object-fit: contain;
}

.section-lottery__tweets {
	display: flex;
	margin-bottom: 40px;
	padding: 0 8px;
	overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.section-lottery__tweets::-webkit-scrollbar {
  display: none;
}

.section-lottery__tweet {
	width: 328px;
	min-width: 328px;
	padding: 0 8px;
}

.section-lottery__tweet-inner {
	padding: 18px 24px 22px;
	border: 1px solid var(--color-red);
	border-radius: 24px;
}

.section-lottery__tweet-top {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
}

.section-lottery__tweet-rsf {
	margin-right: 8px;
	width: 31px;
	height: 31px;
	object-fit: contain;
}

.section-lottery__tweet-heading {
	color: var(--color-red);
	font-family: 'Reservation Wide', sans-serif;
	font-size: 14px;
  font-style: normal;
  font-weight: bold;
}

.section-lottery__tweet-twitter {
	margin-left: auto;
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.section-lottery__tweet-message {
	margin-bottom: 0;
	color: var(--color-red);
	font-family: 'Reservation Wide', sans-serif;
	font-size: 18px;
  font-style: normal;
  font-weight: normal;
	line-height: 1.6;
}
.has-image .section-lottery__tweet-message {
	margin-bottom: 16px;
	font-size: 14px;
}

.section-lottery__tweet-hashtags {
	color: #00AEFF;
}

.section-lottery__tweet-image,
.section-lottery__tweet-image img {
	width: 100%;
	height: auto;
	border-radius: 8px;
	object-fit: cover;
	overflow: hidden;
}

@media (min-width: 768px) {
	.section-lottery {
		display: flex;
		flex-direction: column;
		justify-items: center;
		min-height: 100vh;
	}

	.section-lottery__inner,
	.lottery-1 .section-lottery__inner,
	.lottery-2 .section-lottery__inner {
		padding: 112px 10vw 32px;
	}

	.section-lottery__description {
		margin-left: 20vw;
		width: 50vw;
	}

	.section-lottery__subheading,
	.lottery-3 .section-lottery__subheading,
	.lottery-4 .section-lottery__subheading {
		display: block;
		float: left;
		max-width: 30vw;
		margin-bottom: 80px;
	}
	.lottery-4 .section-lottery__subheading,
	.section-lottery__text-3 {
		margin-bottom: 0;
	}
	.section-lottery__text {
		float: right;
		margin-right: 10vw;
		width: 30vw;
	}
	.section-lottery__balls,
	.section-lottery__numbers,
	.section-lottery__tweets {
		clear: both;
	}

	.ball {
		width: min( 11.94vw, 172px );
		height: min( 11.94vw, 172px );
		font-size: min( 3.89vw, 56px );
	}

	.section-lottery__numbers {
		padding: 25px 40px;
		border-width: 2px;
	}
	.section-lottery__numbers p {
    margin-top: 3px;
		font-size: min( 6.5vw, 68px );
		line-height: 68px;
	}
	.section-lottery__search-icon {
		margin-right: min( 4.62vw, 48px );
		width: min( 7.7vw, 80px );
		height:min( 7.7vw, 80px );
	}
	.section-lottery__twitter-outline {
		width: min( 7.7vw, 80px );
		height: min( 7.7vw, 80px );
	}

	.section-lottery__tweets {
		padding: 0 16px;
	}

	.section-lottery__tweet {
		width: 532px;
		min-width: 532px;
		padding: 0 16px;
	}
	.section-lottery__tweet-inner {
		padding: 22px 32px 32px;
		border-width: 2px;
		border-radius: 32px;
	}

	.section-lottery__tweet-rsf {
		margin-right: 16px;
		width: 47px;
		height: 47px;
	}

	.section-lottery__tweet-heading {
		font-size: 20px;
	}

	.section-lottery__tweet-twitter {
		width: 27px;
	}

	.section-lottery__tweet-message {
		font-size: 24px;
	}
	.has-image .section-lottery__tweet-message {
		font-size: 16px;
	}

	.section-lottery__tweet-image,
	.section-lottery__tweet-image img {
		border-radius: 16px;
	}
}

@media (min-width: 992px) {
	.section-lottery__description {
		margin-left: 40vw;
		width: 30vw;
	}
}