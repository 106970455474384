.simple-page {
	position: relative;
}
.simple-page .back-to {
	padding: 88px 16px 40px;
}
.simple-page .back {
	display: inline-block;
	margin: 0;
	padding: 8px 5px;
	line-height: 16px;
	color: #000000;
	cursor: pointer;
	text-decoration: none;
	text-transform: uppercase;
}
.simple-page-inner {
	padding: 128px 16px 80px;
}
.back-to + .simple-page-inner {
	padding-top: 0;
}

.simple-page-inner h1 {
	margin-bottom: 24px;
}
.simple-page-inner h2 {
	margin-top: 24px;
	margin-bottom: 16px;
	font-size: 24px;
}
.simple-page-inner h3 {
	margin-top: 16px;
	margin-bottom: 8px;
	font-size: 20px;
}
.simple-page-inner h4 {
	margin-bottom: 4px;
	font-size: 16px;
}
.simple-page-inner ul {
	margin-bottom: 1em;
	padding-left: 24px;
	line-height: 1.6;
}
.simple-page-inner p {
	margin-bottom: 1em;
}

.content-block {
	white-space: pre-wrap;
}
.content-block a {
	color: inherit;
	text-decoration: none;
	
	/* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.content-block a:hover {
	color: var(--color-red);
}

@media (min-width: 768px) {
	.simple-page .back-to {
		padding: 120px 16px 66px;
	}
	.simple-page .back {
		padding: 8px 9px;
	}
	.simple-page-inner {
		padding: 192px 10vw 80px;
	}

	.simple-page-inner h1 {
		margin-bottom: 40px;
	}
	.simple-page-inner h2 {
		margin-top: 40px;
		font-size: 32px;
	}
	.simple-page-inner h3 {
		font-size: 24px;
	}
	.simple-page-inner h4 {
		font-size: 20px;
	}
	.page-imprint {
		padding-bottom: 112px;
	}
}