.behind-bars {
	position: absolute;
	display: flex;
	justify-content: space-between;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 16px;
	z-index: -1;
}
.behind-bars .bar {
	width: 1px;
	height: 100%;
	background-color: var(--color-gray);
}

@media (min-width: 768px) {
	.behind-bars {
		padding: 0 10vw;
	}
	.behind-bars .bar {
		width: 2px;
	}
}