#header {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	height: 64px;
	transition: transform .5s linear;
}

body.hide-menu #header {
	transform: translateY(-82px);
}

.header-mobile {
	position: absolute;
	display: flex;
	align-items: center;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;
	padding-right: 146px;
	border-bottom: 1px solid currentColor;
}
.header-mobile.hide {
	clip-path: inset( 0 0 64px 0);
}
.header-mobile.hidden,
.header-desktop.hidden {
	display: none;
}
.header-mobile.showing,
.header-desktop.showing {
	clip-path: inset( 0 0 0 0) !important;
}

.header-burger {
	width: 72px;
	height: 100%;
	padding: 18px 16px;
	border-right: 1px solid currentColor;
	cursor: pointer;
}
.burger-line {
	margin-bottom: 12px;
	height: 1px;
	background-color: currentColor;
}

.header-mobile__logo {
	flex: 1;
	text-align: center;
}

.header-mobile__menu,
.header-discover__menu {
	position: fixed;
	z-index: 1100;
	top: 0;
	left: 100vw;
	width: 100vw;
	height: 100vh;
	transition: transform .3s linear;
}
.header-mobile__menu.open,
.header-discover__menu.open {
	transform: translateX(-100vw);
}

.header-mobile__menu {
	background: #000000;
}

.header-mobile__top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 32px;
	border-bottom: 1px solid currentColor;
}
.header-mobile__lang {
	padding: 8px;
	margin: 0;
	font-size: 24px;
	line-height: 1;
}
.header-mobile__lang a {
	color: inherit;
	text-decoration: none;
}

.header-mobile__close,
.header-discover__menu-close {
	position: relative;
	margin: -8px;
	padding: 8px;
	width: 40px;
	height: 40px;
	cursor: pointer;
}
.header-discover__menu-close {
	display: flex;
	align-items: center;
	width: initial;
}
.header-discover__menu-close p {
	display: none;
	margin-right: 12px;
	color: #000000;
	text-transform: uppercase;
}
.header-mobile__close img,
.header-discover__menu-close img {
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.header-mobile__menu ul,
.header-desktop__menu ul,
.header-discover__list {
	margin: 0;
	padding: 0;
	list-style: none;
}
.header-mobile__menu li {
	border-bottom: 1px solid #ffffff;
}
.header-mobile__menu li a {
	display: inline-block;
	padding: 24px 40px;
	width: 100%;
	font-size: 24px;
	line-height: 1;
	color: inherit;
	text-decoration: none;
}
.header-mobile__menu li a:hover {
	color: var(--color-red);
}
.header-desktop.hide {
	clip-path: inset( 0 0 80px 0);
}

.header-desktop {
	position: absolute;
	display: flex;
	align-items: center;
	top: 0;
	right: 0;
	padding-right: 180px;
	width: 100%;
	height: 100%;
	border-bottom: 1px solid currentColor;
}

.header-desktop__logo {
	position: relative;
	display: block;
	width: 20vw;
}
.header-desktop__logo-rsf {
	position: absolute;
	top: -24px;
	left: 24px;
	width: 152px;
	height: 48px;
	cursor: pointer;
	object-fit: contain;
	transition: opacity .4s .5s linear;
}
.use-ttw .header-desktop__logo-rsf {
	transition-delay: 0s;
	opacity: 0;
}
.header-desktop__logo-ttw {
	position: absolute;
	top: -24px;
	left: 24px;
	width: 208px;
	height: 48px;
	padding: 17px 0;
	cursor: pointer;
	object-fit: contain;
	opacity: 0;
	max-width: 90%;
	transition: opacity .4s linear;
}
.use-ttw .header-desktop__logo-ttw {
	transition-delay: .5s;
	opacity: 1;
}

.header-desktop__menu ul {
	display: flex;
	justify-content: space-around;
	width: 48.75vw;
	padding-left: 24px;
}

.header-desktop__menu li {
	white-space: nowrap;
}
.header-desktop__menu a {
	padding: 24px 1vw;
	color: inherit;
	text-decoration: none;
}

.header-desktop__lang {
	margin: 0 40px 0 auto;
	line-height: 1;	
}
.header-desktop__lang a {
	color: inherit;
	text-decoration: none;
}

.header-discover__menu-open {
	position: fixed;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	padding: 20px 16px;
	height: 63px;
	background-color: var(--color-red);
	color: inherit;
	cursor: pointer;
}
.header-discover__menu-open p {
	margin: 0 12px 0 0;
	font-size: 14px;
	line-height: 1;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}
.header-discover__menu-open img {
	width: 20px;
	height: 20px;
	object-fit: contain;
}

.header-discover__menu {
	padding: 32px 40px 8px;
	background: var(--color-red);
	overflow-y: auto;
}

.header-discover__menu-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 32px;
}

.header-discover__menu-top p {
	margin-bottom: 0;
	line-height: 1;
	text-transform: uppercase;
}

.header-discover__list li {
	margin-bottom: 32px;
}
.header-discover__dot {
	position: relative;
	display: flex;
	align-items: center;
}
.header-discover__dot-icon {
	width: 48px;
	height: 48px;
	margin-right: 16px;
	border: 1px solid #ffffff;
	border-radius: 50px;
	object-fit: contain;
}
.header-discover__country {
	margin: 6px 0 0;
}
.header-discover__link {
	display: inline-block;
	margin-top: 12px;
	padding: 16px 32px;
	text-align: center;
	border: 1px solid currentColor;
	border-radius: 50px;
	color: inherit;
	font-size: 16px;
	line-height: 1;
	letter-spacing: 0.1em;
	text-decoration: none;
	text-transform: uppercase;
}

@media (min-width: 992px) {
	#header {
		height: 80px;
	}

	.header-discover__menu-open {
		padding: 24px;
		height: 79px;
	}
	.header-discover__menu-open p {
		margin-right: 14px;
		font-size: 16px;
	}
	.header-discover__menu-open img {
		width: 27px;
		height: 22px;
		object-fit: contain;
	}
	.header-discover__menu {
		padding: 28px 24px 0 96px;
		width: 575px;
	}
	.header-discover__menu.open {
		transform: translateX(-575px);
	}
	.header-discover__list li {
		margin-bottom: 40px;
		padding-top: 12px;
	}
	.header-discover__menu.open + .header-discover__back-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}

	.header-discover__menu-close p {
		display: block;
	}
	.header-discover__menu-close img {
		width: 16px;
		height: 16px;
	}

}