#cookie-banner {
	position: fixed;
	display: flex;
	justify-content: center;
	z-index: 9999;
	top: 100vh;
	left: 0;
	width: 100vw;
	color: #ffffff;
	opacity: 0;
	transition: transform .3s ease-out, opacity .3 ease-out;
	pointer-events: none;
}
#cookie-banner.show {
	transition-delay: 0.5s;
	transform: translateY(-100%);
	opacity: 1;
	pointer-events: all;
}

#cookie-banner__inner {
	display: flex;
	justify-content:  center;
	align-items: center;
	flex-wrap: wrap;
	max-width: 100%;
	margin: 16px;
	padding: 16px 24px;
	background-color: var(--color-red);
	border-radius: 8px;
}

#cookie-banner__message {
	width: 100%;
	margin: 0 0 24px 0;
}
#cookie-banner__message a {
	color: inherit;
}

#cookie-banner__buttons {
	margin: -8px -16px;
}

#cookie-banner__accept,
#cookie-banner__decline {
	padding: 8px 16px;
	cursor: pointer;
	font-weight: bold;
}

@media (min-width: 768px) {
	#cookie-banner__inner {
		flex-wrap: nowrap;
	}
	#cookie-banner__message {
		width: auto;
		margin: 0 48px 0 0;
	}
	#cookie-banner__buttons {
		display: flex;
	}
}