.section-hero {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background-color: #000000;
}

.section-hero__iframe-adjustment {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}

.section-hero__iframe-wrapper,
.section-hero__iframe {
	width: 100vw;
	height: 100vh;
}

.section-hero__video {
	position: absolute;
	z-index: 2;
	width: 100vw;
	height: 100vh;
	filter: brightness(0.7);
	object-fit: cover;
	opacity: 1;
	transition: opacity .5s linear;
	pointer-events: none;
}
.section-hero.fullscreen .section-hero__video {
	opacity: 0;
}

.section-hero__overlay,
.section-hero__poster {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	cursor: none;
}
.section-hero__overlay {
	z-index: 55;
}
.section-hero.fullscreen .section-hero__overlay {
	pointer-events: none;
}

.section-hero__inner {
	position: relative;
	z-index: 50;
	padding: 104px 16px 0;
	transition: opacity .5s linear;
	pointer-events: none;
}
.section-hero.fullscreen .section-hero__inner {
	opacity: 0;
}

.section-hero__heading {
	margin: 0;
	padding: 0;
	height: 0;
	overflow: hidden;
}
.section-hero__logo {
	width: 100%;
	height: auto;
	margin: 0 0 32px;
}
.section-hero__description {
	width: 380px;
	max-width: 100%;
	padding: 8px 0;
	margin: 0;
	border-top: 1px solid currentColor;
	border-bottom: 1px solid currentColor;
}

.section-hero__mobile-play {
	display: none;
}

.section-hero__scroll {
	position: absolute;
	z-index: 50;
	display: none;
	bottom: 106px;
	right: 90vw;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	text-align: right;
	transition: opacity .5s linear;
	white-space: nowrap;
	pointer-events: none;
}
html[lang="de"] .section-hero__scroll {
	bottom: 129px;
}
.section-hero.fullscreen .section-hero__scroll {
	opacity: 0;
}
.section-hero__scroll .rotate-90 {
	display: inline-block;
	transform: rotate(-90deg);
	transform-origin: right bottom;
}

.section-hero__logo-small {
	position: absolute;
	z-index: 50;
	bottom: 24px;
	left: 16px;
	width: 101px;
	height: 32px;
	object-fit: contain;
	pointer-events: none;
	transition: opacity .5s linear;
}

.section-hero__cursor {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 75%;
	left: 50%;
	width: 120px;
	height: 120px;
	border: 1px solid #ffffff;
	border-radius: 50%;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	text-align: center;
	transform: translate(-60px, -60px);
	-webkit-touch-callout: none; 
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	transition: opacity .5s linear;
}
.section-hero__cursor.hide,
.section-hero.fullscreen .section-hero__cursor,
.section-hero.fullscreen .section-hero__logo-small  {
	opacity: 0;
}

.section-hero__menu-close {
	position: absolute;
	top: 20px;
	right: 16px;
	width: 40px;
	height: 40px;
	padding: 8px;
	pointer-events: all;
}
.section-hero__menu-close img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}


@media (min-width: 768px), (max-width: 767.9px) and (orientation: landscape) {
	.section-hero__inner {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		margin-left: 10%;
		padding: 80px 0 0;
		width: 70%;
		height: 100%;
	}
	.section-hero__cursor {
		top: 85%;
	}
	.section-hero__cursor.moved {
		width: 230px;
		height: 230px;
		font-size: 16px;
		line-height: 230px;
		transform: translate(-115px, -115px);
	}
	.section-hero__scroll {
		display: block;
	}
	.section-hero__logo-small {
		display: none;
	}
}

@media (min-width: 992px), (max-width: 991.9px) and (orientation: landscape){
	.section-hero__inner {
		width: 35%;
	}
}