@font-face {
  font-family: 'Reservation Wide';
  font-style: normal;
  font-weight: normal;
  src: local('Reservation Wide Regular'),
  local('ReservationWide-Regular'),
  url('./fonts/Reservation/ReservationWide-Regular.ttf') format('truetype'),
  url('./fonts/Reservation/ReservationWide-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Reservation Wide';
  font-style: normal;
  font-weight: bold;
  src: local('Reservation Wide Bold'),
  local('ReservationWide-Bold'),
  url('./fonts/Reservation/ReservationWide-Bold.ttf') format('truetype'),
  url('./fonts/Reservation/ReservationWide-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Simplon Mono';
  font-style: normal;
  font-weight: normal;
  src: local('Simplon Mono Regular WebS'),
  local('SimplonMono-Regular-WebS'),
  url('./fonts/SimplonMono/SimplonMono-Regular-WebS.woff2') format('woff2'),
  url('./fonts/SimplonMono/SimplonMono-Regular-WebS.woff') format('woff');
}

:root {
  --color-red: #E50053;
  --color-gray: #E5E5E5;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}



/* Hide the scrollbar */
html, body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}



body {
  margin: 0;
  font-family: 'Simplon Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1, h2, h3, h4, h5 {
  margin-top: 0;
  font-family: 'Reservation Wide', sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 1.2em;
}
h1, h2, h3 {
  font-size: 32px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 16px;
}

p, .p {
  margin-top: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
}

@media (min-width: 768px) {
  h1 {
    font-size: 68px;
  } 
  h2 {
    font-size: 56px;
  } 
  h3 {
    font-size: 48px;
  }
  h4 {
    font-size: 24px;
  }
  h5 {
    font-size: 20px;
  }
}

button {
  background: none;
  border: none;
  outline: none;
	color: currentColor;
}

.mode-dark {
  color: #ffffff;
}
.mode-light {
  color: #000000;
}

.no-scroll {
  overflow: hidden;
}

.ttw-button,
.ttw-button:hover,
.ttw-button:active,
.ttw-button:focus {
  display: inline-block;
  border: 1px solid var(--color-red);
  border-radius: 200px;
  outline: none;
  color: #000000;
  font-size: 16px;
  line-height: 16px;
  padding: 15px 24px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.mode-dark .ttw-button,
.mode-dark .ttw-button:hover,
.mode-dark .ttw-button:active,
.mode-dark .ttw-button:focus {
  color: #ffffff;
}

.p-relative {
  position: relative;
}

/* For the text-slide-up animation */
.tsu-parent {
  overflow: hidden;
}