#footer {
	position: relative;
	padding: 48px 16px;
	background-color: black;
}

#footer a {
	text-decoration: none;
	color: inherit;
}
#footer a:hover {
	color: var(--color-red);
}

#footer .behind-bars {
	opacity: 0.15;
	z-index: 0;
}

.footer-inner {
	position: relative;
	z-index: 3;
}

.footer-heading {
	margin-bottom: 40px;
}

.footer-logo img {
	margin-bottom: 32px;
	width: auto;
	height: 40px;
}

.footer-first-text {
	margin-bottom: 32px;
	white-space: pre-wrap;
}
.footer-first-text a {
	display: block;
}

.footer-second-text {
	margin-bottom: 40px;
	white-space: pre-wrap;
}

.footer-menu {
	margin-bottom: 40px;
}
.footer-menu ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.footer-menu li {
	display: inline-block;
}
.footer-menu li:first-child {
	width: 50%;
}
#footer .footer-menu a {
	text-decoration: underline;
}

.footer-copy-right {
	margin: 0;
	font-size: 14px;
	opacity: 0.4;
}

.footer-back-to-top {
	position: absolute;
	z-index: 100;
	top: 26px;
	right: 16px;
	padding: 2px 6px;
	background: none;
	border: none;
	color: inherit;
	cursor: pointer;
	font-size: 12px;
	line-height: 1;
	letter-spacing: 0.1em;
	outline: none;
	text-transform: uppercase;
	transform: rotate(-90deg);
	transform-origin: bottom right;
}
.footer-back-to-top:hover {
	color: var(--color-red);
}

@media (min-width: 768px){
	#footer {
		padding: 40px 12.5vw 120px 10vw;
	}

	.footer-heading {
		margin-bottom: 80px;
	}

	.footer-row {
		display: grid;
    grid-template-columns: 20vw 30vw 1fr;
	}

	.footer-logo img {
		margin-bottom: 0;
		height: 48px;
	}

	.footer-first {
		padding-right: 1.25vw;
	}

	#footer .footer-menu a {
		text-decoration: none;
	}

	.footer-back-to-top {
		top: 15px;
		right: 5vw;
		font-size: 16px;
	}
}