.section-text-image {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
	overflow: hidden;
}

.section-text-image__background {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.section-text-image__background-caption {
	position: absolute;
	right: 16px;
	bottom: 16px;
	margin: 0;
	font-size: 10px;
}

.section-text-image__inner {
	padding: 96px 16px 16px;
}

.section-text-image__heading {
	margin: 0 0 48px;
	white-space: pre-wrap;
}
.type-censorship .section-text-image__heading {
	margin-bottom: 16px;
}

.section-text-image__text {
	margin: 0;
	white-space: pre-wrap;
}
.section-text-image__censorship {
	position: relative;
	margin-bottom: 32px;
	padding: 75px 0 32px;
	border: 2px solid var(--color-red);
	border-radius: 32px;
	color: var(--color-red);
	mix-blend-mode: screen;
}
.section-text-image__censorship-avatar {
	position: absolute;
	top: 32px;
	left: 32px;
	width: 64px;
	height: 64px;
	object-fit: contain;
	stroke-width: 2;
}
.section-text-image__censorship-logo {
	position: absolute;
	top: 28px;
	right: 34px;
	width: 28px;
	height: 23px;
	object-fit: contain;
}
.section-text-image__censorship-message {
	position: relative;
	padding: 64px 32px;
	border-bottom: 2px solid var(--color-red);
	color: var(--color-red);
}
.section-text-image__censorship-line {
	position: absolute;
	top: 0;
	height: 2px;
	background-color: var(--color-red);
}
.section-text-image__censorship-line.left {
	left: 0;
	width: 35px;
}
.section-text-image__censorship-line.right {
	left: 92px;
	right: 0;
}
.section-text-image__censorship h4 {
	margin: 0;
	text-align: center;
}

.section-text-image__lottery {
	position: relative;
	margin: 0 -16px 48px;
	font-size: 24px;
	text-transform: uppercase;
}

.loop-infinity {
	position: absolute;
	animation: loopInfinity 30s linear infinite;
	white-space: nowrap;
}
.loop-infinity .looping {
	display: inline-block;
	padding-right: 25vw;
}

@keyframes loopInfinity {
  100% { 
    transform: translateX(-50%);
  }
}


.section-text-image__donate-suggestions {
	margin-top: 32px;
}


.section-text-image .ttw-button {
	margin-bottom: 12px;
	background-color: transparent;
	font-family: 'Reservation Wide', sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: bold;
	letter-spacing: 0.1em;
	line-height: 1.2em;
	transition: background-color .2s linear;
}
.section-text-image .ttw-button:hover {
	line-height: 1.2em;
}
.section-text-image__amount,
.section-text-image__donate {
	width: 100%;
}
input.section-text-image__amount,
input.section-text-image__amount:hover,
input.section-text-image__amount:active,
input.section-text-image__amount:focus {
	text-transform: none;
}
.section-text-image__amount.active,
.section-text-image__donate.ttw-button {
	background-color: var(--color-red);
}
.section-text-image__amount h5 {
	margin: 0;
}
.section-text-image__donate-form {
	width: 100%;
}

.section-text-image__amount::-webkit-input-placeholder { color: #ffffff;}
.section-text-image__amount::-ms-input-placeholder { color: #ffffff;}
.section-text-image__amount::placeholder {
  color: #ffffff;
  opacity: 1;
}
.section-text-image__amount:focus::-webkit-input-placeholder { color:transparent; }
.section-text-image__amount:focus::-moz-placeholder { color:transparent; }
.section-text-image__amount:focus:-ms-input-placeholder { color:transparent; }
.section-text-image__amount:focus::placeholder { color:transparent; }

/* Chrome, Safari, Edge, Opera */
.section-text-image__amount::-webkit-outer-spin-button,
.section-text-image__amount::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.section-text-image__donate-paypal {
	width: 90px;
	height: 24px;
	object-fit: contain;
}

.section-text-image__donate-suggestions + .section-text-image__text:not(:empty) {
	margin-top: 28px;
}
.section-text-image__note:not(:empty) {
	margin-top: 8px;
	font-size: 12px;
	white-space: pre-wrap;
}

@media (min-width: 768px) {
	.section-text-image__background-caption {
		right: initial;
		left: 24px;
		bottom: 8px;
		transform: rotate(-90deg);
		transform-origin: left top;
	}

	.section-text-image__inner,
	.type-lottery .section-text-image__inner {
		padding: 112px 10vw 32px;
	}
	.type-donate .section-text-image__inner {
		padding-left: 47.5vw;
	}
	.type-censorship .section-text-image__inner {
		display: flex;
		padding-left: 10vw;
	}

	.type-censorship .section-text-image__info {
		margin-left: 7.5vw;
		width: 42.5vw;
	}

	.section-text-image__heading,
	.type-censorship .section-text-image__heading {
		margin-bottom: 80px;
		width: 42.5vw;
	}
	.type-lottery .section-text-image__heading {
		margin-bottom: 56px;
	}

	.section-text-image__text-wrapper {
		width: 42.5vw;
	}

	.section-text-image__censorship {
		position: relative;
		padding: 126px 0 63px;
		width: 30vw;
		margin: 0;
		padding-bottom: 63px;
	}
	.section-text-image__censorship-avatar {
		top: 64px;
		width: 88px;
		height: 88px;
		stroke-width: 1.5;
	}
	.section-text-image__censorship-logo {
		width: 48px;
    height: 39px;
	}
	.section-text-image__censorship-message {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}
	.section-text-image__censorship-line.left {
		width: 37px;
	}
	.section-text-image__censorship-line.right {
		left: 114px;
	}
	.section-text-image__lottery {
		margin-bottom: 61px;
		height: 83px;
		font-size: 4.72vw;
		font-size: clamp(24px, 4.72vw, 68px);
		line-height: 83px;
	}
	.section-text-image .ttw-button,
	.section-text-image .ttw-button:hover,
	.section-text-image .ttw-button:focus,
	.section-text-image .ttw-button:active {
		border-width: 2px;
		font-size: 20px;
		line-height: 1.2em;
	}

	.section-text-image__donate-paypal {
		width: 105px;
		height: 28px;
	}
}

@media (min-width: 992px) {
	.section-text-image__heading {
		margin-bottom: 120px;
	}
	.section-text-image__text-wrapper {
		display: flex;
		justify-content: space-between;
	}
	.section-text-image__text,
	.section-text-image__amount {
		width: 20vw;
	}
	.type-donate .section-text-image__text {
		width: 100%;
	}

	.section-text-image__donate-suggestions {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}