.single-country {
	position: relative;
}

.back-and-forth {
	padding: 88px 16px 24px;
}
.back-and-forth > * {
	display: inline-block;
	margin: 0;
	padding: 8px 5px;
	line-height: 16px;
	color: #000000;
	cursor: pointer;
	text-decoration: none;
	text-transform: uppercase;
}
.back-and-forth .forth {
	float: right;
}

.article-inner {
	padding: 0 16px 64px;
}

.article-top p {
	margin-bottom: 8px;
}
.article-top h2 {
	margin-bottom: 16px;
}
.article-top__right {
	display: none;
}

.article-main-image {
	border-radius: 16px;
	overflow: hidden;
}
.article-main-image img {
	margin-bottom: 24px;
	width: 100%;
	height: auto;
	aspect-ratio: 1.366;
	border-radius: 16px;
	overflow: hidden;
	object-fit: cover;
}
.article-main-image-caption {
	position: absolute;
	bottom: 0;
	right: 0;
	font-size: 10px;
}

.article-excerpt,
.article-quote,
.article-blocks__block {
	margin-bottom: 24px;
	white-space: pre-wrap;
}

.article-quote:before {
	content: open-quote;
}
.article-quote:after {
	content: close-quote;
}

.article-blocks__logo {
	margin: 32px 0;
	text-align: center;
}

.article-blocks__lottery {
	margin: 32px 0 0;
}
.article-blocks__lottery-label {
	display: none;
}
.article-blocks__lottery-number {
	margin: 0;
	font-size: 32px;
	line-height: 1.2;
}

.article-blocks__index {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 32px 0;	
}
.article-blocks__index-before {
	margin-bottom: 16px;
	width: 100%;
}
.article-blocks__index-number {
	margin-bottom: 0;
	margin-right: 24px;
	font-size: 88px;
	line-height: 88px;
	letter-spacing: -0.1em;
}
.article-blocks__index-after {
	flex: 1;
	margin-bottom: 0;
	max-width: 150px;
}

.article-blocks__image {
	margin-bottom: 32px;
	border-radius: 16px;
	overflow: hidden;
}
.article-blocks__image img {
	width: 100%;
	aspect-ratio: 1.366;
	border-radius: 16px;
	overflow: hidden;
}

@media (min-width: 768px) {
	.back-and-forth {
		padding: 120px 16px 16px;
	}
	.back-and-forth > * {
		padding: 8px 9px;
	}
	.article-inner {
		padding: 0 10% 80px;
	}

	.article-top {
		display: flex;

	}
	.article-top p {
		margin-bottom: 24px;
	}
	.article-top h2 {
		margin-bottom: 40px;
	}
	.article-top__left {
		padding-right: 16px;
		min-width: 50%;
	}
	.article-top__middle {
		flex: 1;
	}
	.article-top__right-button,
	.article-top__right-button:hover {
		position: relative;
		padding: 24px 73px 24px 32px;
	}
	.article-top__right-button-icon {
		position: absolute;
		top: 20px;
		right: 32px;
		height: 22px;
		width: 27px;
		object-fit: contain;
	}

	.article-main-image img {
		margin-bottom: 40px;
		aspect-ratio: 1.777;
	}
	.article-main-image-caption {
		bottom: 10px;
	}

	.article-excerpt,
	.article-quote {
		margin-bottom: 80px;
	}
	.article-excerpt {
		font-size: 28px;
	}

	.article-blocks {
		margin-left: 25%;
		width: 62.5%;
	}

	.article-blocks__logo {
		position: relative;
		margin: 0;
	}
	.article-blocks__logo-image {
		position: absolute;
		left: -20vw;
		width: 15vw;
	}
	
	.article-blocks__lottery {
		margin: 40px 0;
	}
	.article-blocks__lottery-label {
		display: block;
		margin-bottom: 16px;
	}
	.article-blocks__lottery-number {
		font-size: 4.722vw;
	}

	.article-blocks__index-wrapper {
		position: relative;
	}
	.article-blocks__index {
		position: absolute;
		display: block;
		left: -20vw;
		width: 15vw;
	}
	.article-blocks__index-before {
		margin-bottom: 8px;
		max-width: 200px;
	}
	.article-blocks__index-number {
		margin-bottom: 8px;
		margin-right: 0;
	}

	.article-blocks__image {
		margin-bottom: 40px;
	}
	.article-blocks__image img {
		aspect-ratio: 1.777;
	}
}

@media (min-width: 992px) {
	.article-top__right {
		display: block;
	}
}